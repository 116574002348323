/* Color palette */

$light-gray: #9f9f9f;
$gray: #7a7a7a;

$very-light-gray: #bdbdbd;

$cream: #fcf8ec;
$dark-gray: #1c1c1c;

/* Color aliases */

$primary-color: $light-gray;
$primary-color-dark: $gray;

$secondary-color: $very-light-gray;
$secondary-color-dark: $light-gray;
