/* Sass imports */

@use 'sass:color';
@use 'sass:meta';
@use 'reset';
@use 'variables';

/* Universal styles */

body {
  @extend .font-default;
}

a, a:active, a:focus, a:visited, a:hover {
  color: variables.$primary-color;
  text-decoration: none;

  &.anchor {
    display: block;
    position: relative;
    top: -4vh;
    visibility: hidden;
  }
}

a:hover {
  color: variables.$primary-color-dark;
  cursor: pointer;
}

::selection {
  background: color.adjust(variables.$primary-color, $lightness: 10%);
}

/* Layout styles */

section {
  background-repeat: repeat;
  background-size: 5vw;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  overflow: hidden;
  padding: 4vh 2vh;
  width: calc(100vw - 4vh);

  &:nth-child(odd) {
    background-image: url('../images/backgrounds/dot-light.png');
    color: variables.$dark-gray;

    &.glass {
      background-image: url('../images/backgrounds/triangle-light.png');
    }

    svg {
      stroke: variables.$dark-gray;
    }
  }

  &:nth-child(even) {
    background-image: url('../images/backgrounds/dot-dark.png');
    color: variables.$cream;

    &.glass {
      background-image: url('../images/backgrounds/triangle-dark.png');
    }

    svg {
      stroke: variables.$cream;
    }

    a, a:active, a:focus, a:visited, a:hover {
      color: variables.$secondary-color;
      display: inline;
      padding-bottom: 0px;
    }

    a:hover {
      color: variables.$secondary-color-dark;
      cursor: pointer;
    }

    ::selection {
      background: color.adjust(variables.$secondary-color, $lightness: 10%);
    }
  }

  &.splash {
    justify-content: flex-end;
    min-height: 48vh;
    padding: 25vh 8vw;
    width: 84vw;
  }

  &.showcase {
    p {
      &:first-of-type {
        font-size: 1.5em;
        text-transform: lowercase;
      }

      &:not(:first-of-type) {
        font-size: 1em;
      }
    }
  }

  div.photos {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin-left: -2vh;
    padding-left: 0;
    padding-top: 2em;
    width: calc(100vw + 2vh);

    div.photos.secondary {
      display: flex;
      flex-direction: row;
      padding-bottom: 2vw;
      padding-left: 0;
      padding-top: 0;

      a, img {
        max-height: 12vh;
        max-width: calc(32vw + (2vh / 3));
        padding-left: 0;

        &:not(:last-of-type) {
          padding-bottom: 0;
          padding-right: 2vw;
        }
      }
    }

    img {
      max-height: 40vh;
      max-width: 100vw;
      padding-left: 0;
    }
  }
}


/* Typography styles */

.font-default {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  font-weight: 700;
}

h1 {
  padding-bottom: 0.8em;

  svg {
    stroke-width: 4px;
    width: 80vw;
  }
}

h2 {
  font-size: 1em;
  text-transform: lowercase;
}

h3 {
  svg {
    max-height: 10vh;
    max-width: 90vw;
  }
}

p {
  font-size: 1.5em;
  line-height: 1.1em;
  padding-left: 2.5vw;
  padding-top: 0.8em;
  width: 82vw;

  &:first-of-type {
    padding-top: 1.5em;
  }
}

svg {
  fill-opacity: 0;
  overflow: visible;
  stroke-width: 2px;
}

/* Media queries */

@include meta.load-css('queries');
